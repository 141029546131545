import React from "react";
import Button from "@/components/Button/button";
import SbEditable from "storyblok-react";

const SbButton = (props) => {
  const {
    blok: { link, text, size, style, accent_color, icon },
    fullWidthOnMobile,
    className,
  } = props;

  return (
    <SbEditable content={props.blok}>
      {link ? (
        <Button
          link={link}
          size={size}
          btnStyle={style}
          accent_color={accent_color}
          icon={icon}
          className={className}
          fullWidthOnMobile={fullWidthOnMobile}
        >
          {text}
        </Button>
      ) : null}
    </SbEditable>
  );
};

export default SbButton;
